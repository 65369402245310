import { Controller } from "@hotwired/stimulus"
import { createConsumer } from "@rails/actioncable"

export default class extends Controller {
    static targets = ["button"]

    connect() {
        this.userId = this.element.dataset.userId;

        // Subscribe to ActionCable to listen for subscription updates
        this.channel = createConsumer().subscriptions.create(
            { channel: "SubscriptionStatusChannel", user_id: this.userId },
            {
                received: (data) => {
                    if (data.status === "success") {
                        // Trigger Turbo Stream via ActionCable (no dynamic JS rendering)
                        console.log("Subscription successful");
                    }
                }
            }
        );
    }

    // Function to handle the "Get started" button click
    showPricingOptions(event) {
        event.preventDefault();

        const button = this.buttonTarget;

        // Disable the button and show the loading state
        button.disabled = true;
        button.querySelector(".when-enabled").classList.add("hidden");
        button.querySelector(".when-disabled").classList.remove("hidden");

        // Simulate sending a message to the native app
        window.TurboNativeBridge.postMessage("showPricingOptions");

        // Optional: revert the button after a timeout if no broadcast happens
        setTimeout(() => {
            button.disabled = false;
            button.querySelector(".when-enabled").classList.remove("hidden");
            button.querySelector(".when-disabled").classList.add("hidden");
        }, 20000); // Revert after 20 seconds
    }
}
