import consumer from "./consumer"

// Subscribe to AssistantRunStatusChannel, no need to pass user_id if the server already knows current_user
consumer.subscriptions.create("AssistantRunStatusChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
    console.log("Connected to AssistantRunStatusChannel");
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    const chatIndicator = document.getElementById("chat-indicator");
    const submitButton = document.getElementById("submit_button");
    const progressButton = document.getElementById("progress_button");

    if (data.status === "starting") {
      console.log("starting");
      chatIndicator.style.visibility = 'visible';
      submitButton.classList.add('hidden');
      progressButton.classList.remove('hidden');
    } else if (data.status === "done") {
      console.log("stopping");
      chatIndicator.style.visibility = 'hidden';
      submitButton.classList.remove('hidden');
      progressButton.classList.add('hidden');
    }
  }
});
